import React, { Suspense, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { roles } from "../constants/roles";
import { toastr } from "react-redux-toastr";
import Loader from "../shared/components/Loader";
import { useSelector } from "react-redux";
import { usePrivileges } from "../shared/hooks/useSessionPrivileges";

// Create a React component that will handle the route rendering with proper hook context
const RouteRenderer = ({ route, renderProps }) => {
  // Now hooks are used directly inside a component function
  const profile = useSelector((state) => state.firebase.profile);
  const isAuthenticated = !profile?.isEmpty;
  const { hasPrivilege } = usePrivileges();

  useEffect(() => {
    if (!route.bypass) {
      document.title = route.title
        ? route.title
        : route.name
        ? `${route.name} | Dimension Waste Management`
        : "Waste Removal, Mattress Removal and Dumpster Rentals - Same Day Junk Removal | Dimension Waste Management";
    }
  }, [route.bypass, route.title, route.name]);

  // Handle banned users
  if (profile?.isBanned && !["/", "/account"].includes(route.path)) {
    return <Redirect to="/account" />;
  }

  // Check access permissions
  const needAccess = route.privilege ?? false;
  const hasAccess = needAccess ? hasPrivilege(route.privilege) : true;

  if (needAccess && !hasAccess) {
    return isAuthenticated ? <Redirect to={roles[profile.userType]?.homepage || "/"} /> : <Redirect to="/" />;
  }

  // Render the component with Suspense
  return (
    <Suspense fallback={<Loader />}>
      <route.component {...renderProps} />
    </Suspense>
  );
};

// Memoize the RouteRenderer to prevent unnecessary re-renders
const MemoizedRouteRenderer = React.memo(RouteRenderer);

export const renderComponent = (route) => {
  // Return a render prop function for Route that doesn't use hooks
  return (props) => {
    // Show toast here since it's outside of the component
    if (route.showToastOnAccess) {
      toastr.info("You have a ban imposed for your account. Redirecting...");
    }

    // Return the actual component which safely uses hooks
    return <MemoizedRouteRenderer route={route} renderProps={props} />;
  };
};

import { useSelector } from "react-redux";

export const usePrivileges = () => {
  const sessionPrivileges = useSelector((state: any) => state?.auth?.privileges || []);
  const profilePrivileges = useSelector((state: any) => state?.firebase?.profile?.privileges || []); // added privs to profile - overrides basic
  const restrictedPrivileges = useSelector((state: any) => state?.firebase?.profile?.privileges_restricted || []); // removed privs from profile - overrides basic

  let combinedPrivileges = [...new Set([...sessionPrivileges, ...profilePrivileges])];
  combinedPrivileges = combinedPrivileges.filter((privilege) => !restrictedPrivileges.includes(privilege));

  const hasPrivilege = (privilegeName: string) => combinedPrivileges.includes(privilegeName);

  return {
    privileges: combinedPrivileges,
    hasPrivilege
  };
};

export const aeSales: any = {
  "adam@getdimension.com": "Adam",
  "albert@getdimension.com": "Albert",
  "ben@getdimension.com": "Ben",
  "ben@trashwarrior.com": "Ben",
  "cs02@trashwarrior.com": "Karen",
  "cs07@getdimension.com": "Jhicko",
  "cs07@trashwarrior.com": "Jhicko",
  "cs08@getdimension.com": "Albert",
  "cs08@trashwarrior.com": "Albert",
  "cs13@trashwarrior.com": "Aaron",
  "dhruv@getdimension.com": "Dhruv",
  "dhruv@trashwarrior.com": "Dhruv",
  "ehelbig@getdimension.com": "Eric",
  "ehelbig@trashwarrior.com": "Eric",
  "frank@getdimension.com": "Frank",
  "frank@trashwarrior.com": "Frank",
  "garrett@getdimension.com": "Garrett",
  "greg@getdimension.com": "Greg",
  "greg@trashwarrior.com": "Greg",
  "jcubacub@getdimension.com": "John",
  "jcubacub@trashwarrior.com": "John",
  "joy@getdimension.com": "Joy",
  "justin@getdimension.com": "Justin",
  "marshall@trashwarrior.com": "Marshall",
  "moustafa@getdimension.com": "Moustafa",
  "nastassia@getdimension.com": "Nastassia",
  "sean@getdimension.com": "Sean",
  "sean@trashwarrior.com": "Sean",
  "sferris@getdimension.com": "Scott",
  "zach@getdimension.com": "Zach",
  "zainab@getdimension.com": "Zainab",
  "zainab@trashwarrior.com": "Zainab",
  "philip@getdimension.com": "Philip",
  "rudolf@getdimension.com": "Rudolf",
  "nicholas@getdimension.com": "Nicholas",
  "nelson@getdimension.com": "Nelson",
};
